import React, { ReactNode, useMemo } from "react";

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  SxProps,
  Theme,
  styled,
} from "@mui/material";
import { useNavigate } from "react-router";

import { ToastAction, ToastOptions, ToastType } from "../../../types/toast";

interface PropsBase extends Omit<ToastOptions, "id" | "message" | "type"> {
  type?: ToastType;
  open: boolean;
  sx?: SxProps<Theme>;
  onClose?: () => void;
}

interface WithMessage {
  message: string;
  children?: ReactNode;
}

interface WithChildren {
  message?: string;
  children: ReactNode;
}

type Props = PropsBase & (WithMessage | WithChildren);

const MIN_TOAST_DURATION = 2000; // 2 seconds
const MAX_TOAST_DURATION = 8000; // 8 seconds

export default function Toast({
  type = "success",
  message,
  children,
  open,
  duration,
  loading,
  actions,
  sx,
  onClose,
}: Props) {
  const navigate = useNavigate();

  const toastDuration = useMemo(() => {
    if (duration || duration === null) {
      return duration;
    }

    if (!message) {
      return MIN_TOAST_DURATION;
    }

    const baseDuration = message.length * 50;
    return Math.min(Math.max(baseDuration, MIN_TOAST_DURATION), MAX_TOAST_DURATION);
  }, [duration, message]);

  const handleActionClick = (action: ToastAction) => {
    switch (action.type) {
      case "close":
        onClose?.();
        break;
      case "link":
        navigate(action.to);
        break;
      case "callback":
        action.onClick();
        break;
    }
  };

  const onSnackbarCloseEvent = () => {
    if (!actions || typeof duration === "number") {
      onClose?.();
    }
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={toastDuration}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={onSnackbarCloseEvent}
      sx={sx}
    >
      <ToastAlert severity={type} icon={false}>
        <ToastWrapper>
          <MessageWrapper>
            {loading && <CircularProgress size={12} color={type} />}
            {message}
            {children}
          </MessageWrapper>
          {actions && (
            <ActionsWrapper type={type}>
              {actions.map((action) => (
                <ToastButton
                  key={action.label}
                  color="secondary"
                  onClick={() => handleActionClick(action)}
                >
                  {action.label}
                </ToastButton>
              ))}
            </ActionsWrapper>
          )}
        </ToastWrapper>
      </ToastAlert>
    </Snackbar>
  );
}

const ToastAlert = styled(Alert)`
  padding: 0;
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.15),
    0px -5px 10px -3px rgba(0, 0, 0, 0.05);

  .MuiAlert-message {
    padding: 0;
    width: 100%;
  }
`;

const ToastButton = styled(Button)`
  padding: 0;
  background: transparent !important;
  color: inherit !important;
`;

const ToastWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    flex-direction: column;
  }
`;

const MessageWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.875rem 1rem;
  flex: 1;
  justify-content: center;
`;

const ActionsWrapper = styled(Box)<{ type: ToastType }>(({ type, theme }) => {
  const color = theme.palette[type].main;

  return `
    box-sizing: border-box;
    display: flex;
    border-left: 1px solid ${color};
    padding: 0.75rem 1rem;
  
    ${theme.breakpoints.down("sm")} {
      width: 100%;
      padding-left: 0;
      border-left: none;
      border-top: 1px solid ${color};;
      justify-content: end;
    }
  `;
});
